import { useMemo, useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { NumericFormat } from 'react-number-format'
import Avatar from 'components/Avatar'
import { formatDate } from 'utils/dates'
import { PaginatedTokenTransactionList, TokenTransaction } from 'types/token/profitShare'
import { GenericTokenIcon } from 'icons'
import { DISTRIBUTION_TRANSFER_TYPES } from 'types/token/tokenDistribution'
import { TOKEN_TYPE_OPTIONS } from 'types/token/basicToken'
import useDebounce from 'hooks/useDebounce'
import { useTransactions } from 'services/api/user'
import KeeprTable from 'components/KeeprTable'

interface PeopleDetailsStockOptionsTransactionsProps {
  userId: string
}

const initialTransactionsData: PaginatedTokenTransactionList = {
  count: 0,
  next: null,
  previous: null,
  results: [],
}

const PeopleDetailsStockOptionsTransactions = ({
  userId,
}: PeopleDetailsStockOptionsTransactionsProps) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 5,
  })
  const [globalFilter, setGlobalFilter] = useState<string>('')
  const debouncedSearchParam = useDebounce({ value: globalFilter || '' })

  const { data: transactions = initialTransactionsData, isLoading } = useTransactions({
    userId,
    searchTerm: debouncedSearchParam,
    tokenType: TOKEN_TYPE_OPTIONS.StockOption,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'token.name',
          header: 'Name',
          Cell: ({ cell, row }) => {
            const tokenName = cell.getValue<string>()
            return (
              <Stack direction='row' alignItems='center' gap={1}>
                {row.original.token?.logo_thumbnail ? (
                  <Avatar
                    type='round'
                    src={row.original.token.logo_thumbnail}
                    size={32}
                    name='Token Logo'
                  />
                ) : (
                  <GenericTokenIcon
                    sx={{ fontSize: 32, verticalAlign: 'middle', color: 'text.primary' }}
                  />
                )}
                <Stack alignItems='flex-start' overflow='hidden'>
                  <Typography variant='body2' noWrap={true}>
                    {tokenName}
                  </Typography>
                  <Typography variant='caption' color='text.secondary'>
                    {row.original.token?.abbreviation}
                  </Typography>
                </Stack>
              </Stack>
            )
          },
        },
        {
          header: 'Date',
          accessorKey: 'txn_date',
          Cell: ({ cell }) => {
            const txnDate = cell.getValue<string>()
            return <Typography variant='body2'>{formatDate(txnDate, 'MMM. dd, yyyy')}</Typography>
          },
        },
        {
          header: 'Event type',
          accessorKey: 'exercise',
          Cell: ({ cell }) => {
            const exercise = cell.getValue<boolean>()
            return <Typography variant='body2'>{exercise ? 'Exercise' : 'Vested'}</Typography>
          },
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          Cell: ({ cell, row }) => {
            const amount = cell.getValue<number>()
            return (
              <Typography
                variant='body2'
                color={
                  row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Credit &&
                  row.original.amount > 0
                    ? 'success.main'
                    : 'inherit'
                }
              >
                {row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Credit &&
                row.original.amount > 0
                  ? '+'
                  : row.original.transaction_type === DISTRIBUTION_TRANSFER_TYPES.Debit
                  ? '-'
                  : ''}

                <NumericFormat value={amount} displayType={'text'} thousandSeparator />
              </Typography>
            )
          },
        },
      ] as MRT_ColumnDef<TokenTransaction>[],
    [],
  )

  return (
    <KeeprTable
      columns={columns}
      data={transactions?.results || []}
      globalFilterPlaceholder='Scheme name'
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      initialState={{
        showGlobalFilter: true,
      }}
      rowCount={transactions?.count || 0}
      state={{
        showSkeletons: isLoading,
        showProgressBars: isLoading,
        pagination,
        globalFilter,
      }}
      enableSorting={false}
      muiTablePaperProps={{
        elevation: 0,
      }}
    />
  )
}

export default PeopleDetailsStockOptionsTransactions
