import { useNavigate, useParams } from 'react-router-dom'
import PreviewCustomTokenPayout from './PreviewCustomTokenPayout'
import { useTokenDetails } from 'services/api/token'
import { useEffect } from 'react'
import { useBreadcrumbsActions } from 'hooks/useBreadcrumbsStore'
import {
  BlurCircularOutlined as BlurCircularOutlinedIcon,
  ArrowBackIosNew as ArrowBackIosNewIcon,
} from '@mui/icons-material'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useCustomTokenPayoutDetails } from 'services/api/customToken'

const CustomTokenPayout = () => {
  const { tokenId = '', payoutId = '' } = useParams<{ tokenId: string; payoutId: string }>()
  const { setBreadcrumbs } = useBreadcrumbsActions()
  const navigate = useNavigate()

  const { data: token, isLoading: isTokenLoading } = useTokenDetails({ tokenId })
  const { data: payout, isLoading: isPayoutLoading } = useCustomTokenPayoutDetails({
    tokenId,
    payoutId: Number(payoutId),
  })

  useEffect(() => {
    if (isTokenLoading || !token || isPayoutLoading || !payout) {
      return
    }

    setBreadcrumbs({
      icon: BlurCircularOutlinedIcon,
      list: [
        {
          text: 'Schemes',
          href: '/assets',
        },
        {
          text: token.name,
          href: `/assets/custom-asset/${token.uuid}`,
        },
        {
          text: payout.name,
        },
      ],
    })
  }, [isPayoutLoading, isTokenLoading, payout, setBreadcrumbs, token])

  if (!token || !payout) {
    return null
  }

  return (
    <>
      <Stack mt={7} mb={4} direction='row' gap={1} alignItems='center'>
        <IconButton
          aria-label='back'
          size='small'
          sx={{ color: 'text.primary' }}
          onClick={() => navigate(`/assets/custom-asset/${tokenId}/payouts`)}
        >
          <ArrowBackIosNewIcon fontSize='inherit' />
        </IconButton>

        <Typography variant='h4'>Payout</Typography>
      </Stack>

      <Box maxWidth='960px'>
        <PreviewCustomTokenPayout tokenId={tokenId} payout={payout} isEditMode={true} />
      </Box>
    </>
  )
}

export default CustomTokenPayout
