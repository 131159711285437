import { CssBaseline, ThemeOptions, ThemeProvider, createTheme } from '@mui/material'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { getSubdomain, redirectToSubdomain } from 'utils/domain'
import { useEffect, useMemo, lazy } from 'react'
import {
  useOrganizationActions,
  useOrganizationIsDarkTheme,
  useOrganizationSubdomain,
  useOrganizationThemeColor,
  useRestrictedUse,
} from './hooks/useOrganizationStore'

import Error from 'components/Error'
import Login from 'auth/login/Login'
import PageLayout from 'components/layout/PageLayout'
import { PrivateRoute } from 'auth/components/PrivateRoute'
import Register from 'auth/register/Register'
import ResetPassword from 'auth/reset-password/ResetPassword'
import SetPasswordWithOTP from 'auth/register/SetPasswordWithOTP'

import { USER_ROLE } from 'types/user'
import VerificationEmailExpired from 'auth/register/VerificationEmailExpired'
import { lightPalette, darkPalette } from 'theme/palette'
import { tokensTheme } from 'theme/theme'
import useIdleTimer from 'hooks/useIdleTimer'
import { useIsUserAuthenticated } from './hooks/useUserAuthStore'
import { useOrganizationTheme } from 'services/api/organization'
import { useProfileUUID, useUserProfileActions } from 'hooks/useUserProfileStore'
import TitledPage from 'components/TitledPage'
import { useProfile } from 'services/api/user'
import PeopleDetailsAssets from './features/people/details/PeopleDetailsAssets'
import PeopleDetailsTransactions from './features/people/details/PeopleDetailsTransactions'
import PeopleDetailsPayouts from './features/people/details/PeopleDetailsPayouts'
import ProfitShareHolders from './features/tokens/token/profit-share/ProfitShareHolders'
import ProfitShareDistributions from './features/tokens/token/profit-share/ProfitShareDistributions'
import ProfitSharePayouts from './features/tokens/token/profit-share/ProfitSharePayouts'
import General from './features/settings/tabs/general/General'
import PlansAndBilling from './features/settings/tabs/plans-and-billing/PlansAndBilling'
import { RESTRICTED_USE_ALLOWED_ROUTE } from 'constants/common'
import CustomTokenUsers from 'features/tokens/token/custom-token/CustomTokenUsers'
import StockOptionHolders from 'features/tokens/token/stock-options/stock-option-holders/StockOptionHolders'
import CustomTokenDistributions from 'features/tokens/token/custom-token/CustomTokenDistributions'
import CustomTokenPayouts from 'features/tokens/token/custom-token/payout/CustomTokenPayouts'
import CustomTokenPayout from 'features/tokens/token/custom-token/payout/CustomTokenPayout'
import StockOptionExercising from 'features/tokens/token/stock-options/StockOptionExecrising'

const Home = lazy(() => import('./features/home/Home'))
const Dashboard = lazy(() => import('./features/home/dashboard/Dashboard'))
const UserDashboard = lazy(() => import('./features/home/dashboard/user/UserDashboard'))
const OrganizationDashboard = lazy(
  () => import('./features/home/dashboard/organization/OrganizationDashboard'),
)
const Wallet = lazy(() => import('./features/wallet/Wallet'))

const People = lazy(() => import('./features/people/People'))
const PeopleDetails = lazy(() => import('./features/people/details/PeopleDetails'))

const CreateToken = lazy(() => import('./features/tokens/create-token/CreateToken'))
const TokenList = lazy(() => import('./features/tokens/token-list/TokenList'))
const TokenDetails = lazy(() => import('./features/tokens/token/TokenDetails'))

const GrantTokenOptions = lazy(
  () => import('./features/tokens/token/stock-options/grant-token-options/GrantTokenOptions'),
)

const CreatePayout = lazy(() => import('./features/tokens/token/profit-share/payout/CreatePayout'))
const CreateSelectiveCashSharePayout = lazy(
  () =>
    import(
      './features/tokens/token/custom-token/payout/selective-cash-share/CreateSelectiveCashSharePayout'
    ),
)
const Payout = lazy(() => import('./features/tokens/token/profit-share/payout/Payout'))

const Settings = lazy(() => import('./features/settings/Settings'))

export const APP_DEFAULT_SUBDOMAIN = 'app'
export const CURRENT_SUBDOMAIN = getSubdomain()
const timeout = 1000 * 60 * 5 // Timeout set to 5 min

function App() {
  const isUserAuthenticated = useIsUserAuthenticated()
  const userId = useProfileUUID()
  const organizationThemeColor = useOrganizationThemeColor()
  const isDarkTheme = useOrganizationIsDarkTheme()
  const organizationSubdomain = useOrganizationSubdomain()

  const { setProfile, setRedirectUrl } = useUserProfileActions()
  const { setOrganization } = useOrganizationActions()

  const isRestrictedUse = useRestrictedUse()

  useIdleTimer(timeout, isUserAuthenticated)

  const { data: organizationThemeData, error: organizationError } = useOrganizationTheme({
    subdomain: CURRENT_SUBDOMAIN,
    options: {
      enabled: CURRENT_SUBDOMAIN !== APP_DEFAULT_SUBDOMAIN,
    },
  })

  const { data: profileData, isLoading: isProfileDataLoading } = useProfile({
    options: {
      enabled: isUserAuthenticated,
    },
  })

  useEffect(() => {
    if (profileData) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { organization, workspaces, ...profile } = profileData

      if (userId !== profileData.uuid) {
        setRedirectUrl('')
      }
      setProfile(profile)
      setOrganization(organization)
    }
  }, [profileData, setOrganization, setProfile, setRedirectUrl, userId])

  useEffect(() => {
    if (organizationThemeData) {
      setOrganization(organizationThemeData)
    }
  }, [organizationThemeData, setOrganization])

  useEffect(() => {
    if (organizationError) {
      redirectToSubdomain(APP_DEFAULT_SUBDOMAIN)
    }
  }, [organizationError])

  const organizationTheme = useMemo(() => {
    const shouldApplyTheme = CURRENT_SUBDOMAIN === organizationSubdomain && organizationThemeColor
    const primaryColor = shouldApplyTheme ? organizationThemeColor : lightPalette.keepr.main

    return createTheme({
      ...tokensTheme,
      palette: {
        ...(isDarkTheme ? darkPalette : lightPalette),
        primary: {
          main: primaryColor,
        },
      },
    } as ThemeOptions)
  }, [organizationThemeColor, organizationSubdomain, isDarkTheme])

  if (
    CURRENT_SUBDOMAIN !== APP_DEFAULT_SUBDOMAIN &&
    (!CURRENT_SUBDOMAIN || CURRENT_SUBDOMAIN !== organizationSubdomain) &&
    !isUserAuthenticated
  ) {
    return null
  }

  if (isProfileDataLoading) {
    return null
  }

  return (
    <ThemeProvider theme={organizationTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path='login' element={<Login />} />
          <Route element={<PrivateRoute isAllowed={CURRENT_SUBDOMAIN !== APP_DEFAULT_SUBDOMAIN} />}>
            <Route path='reset-password' element={<ResetPassword />} />
            <Route
              path='reset-password/:otp'
              element={<SetPasswordWithOTP isResetPassword={true} />}
            />
          </Route>
          <Route path='set-password/:otp' element={<SetPasswordWithOTP />} />
          <Route path='verification-email/' element={<VerificationEmailExpired />} />
          <Route element={<PrivateRoute isAllowed={getSubdomain() === APP_DEFAULT_SUBDOMAIN} />}>
            <Route path='register' element={<Register />} />
          </Route>

          <Route
            element={
              <PrivateRoute isAllowed={isUserAuthenticated}>
                <PageLayout />
              </PrivateRoute>
            }
          >
            <Route
              element={
                <PrivateRoute
                  isAllowed={!isRestrictedUse}
                  redirectPath={RESTRICTED_USE_ALLOWED_ROUTE}
                />
              }
            >
              <Route path='/' element={<Home />} />

              <Route
                path='dashboard'
                element={<TitledPage title='Keepr - User Dashboard' component={<Dashboard />} />}
              >
                <Route index element={<UserDashboard />} />
                <Route
                  element={
                    <PrivateRoute
                      isAllowed={profileData?.role === USER_ROLE.Admin}
                      redirectPath='/'
                    />
                  }
                >
                  <Route
                    path='organization'
                    element={
                      <TitledPage
                        title='Keepr - Organization Dashboard'
                        component={<OrganizationDashboard />}
                      />
                    }
                  />
                </Route>
              </Route>
              <Route
                path='wallet'
                element={<TitledPage title='Keepr - Wallet' component={<Wallet />} />}
              />
              <Route
                element={
                  <PrivateRoute
                    isAllowed={profileData?.role === USER_ROLE.Admin}
                    redirectPath='/'
                  />
                }
              >
                <Route
                  path='people/create-users'
                  element={<TitledPage title='Keepr - Create Users' component={<People />} />}
                />
                <Route
                  path='people/created'
                  element={<TitledPage title='Keepr - People' component={<People />} />}
                />
                <Route
                  path='people/invited'
                  element={<TitledPage title='Keepr - People' component={<People />} />}
                />
                <Route
                  path='people/active'
                  element={<TitledPage title='Keepr - People' component={<People />} />}
                />
                <Route
                  path='people/inactive'
                  element={<TitledPage title='Keepr - People' component={<People />} />}
                />
                <Route
                  path='people/:userId'
                  element={
                    <TitledPage title='Keepr - Member details' component={<PeopleDetails />} />
                  }
                >
                  <Route path='' element={<PeopleDetailsAssets />} />
                  <Route path='transactions' element={<PeopleDetailsTransactions />} />
                  <Route path='payouts' element={<PeopleDetailsPayouts />} />
                </Route>
                <Route
                  path='people'
                  element={<TitledPage title='Keepr - People' component={<People />} />}
                />

                <Route
                  path='assets'
                  element={<TitledPage title='Keepr - Schemes' component={<TokenList />} />}
                />
                <Route
                  path='assets/create'
                  element={<TitledPage title='Keepr - Create Scheme' component={<CreateToken />} />}
                />
                <Route
                  path='assets/edit/:tokenId'
                  element={<TitledPage title='Keepr - Edit Scheme' component={<CreateToken />} />}
                />

                <Route element={<PrivateRoute isAllowed={false} redirectPath='assets' />}>
                  <Route path='assets/stock-option' />
                  <Route path='assets/profit-share' />
                  <Route path='assets/custom-asset' />
                </Route>

                <Route path='assets/stock-option/:tokenId'>
                  <Route
                    path=''
                    element={
                      <TitledPage title='Keepr - Stock Option' component={<TokenDetails />} />
                    }
                  >
                    <Route path='' element={<StockOptionHolders />} />
                    <Route path='exercising' element={<StockOptionExercising />} />
                  </Route>
                  <Route
                    path='grant-asset-options'
                    element={
                      <TitledPage
                        title='Keepr - Stock Option - Grant Scheme Options'
                        component={<GrantTokenOptions />}
                      />
                    }
                  />
                </Route>

                <Route path='assets/profit-share/:tokenId'>
                  <Route
                    path=''
                    element={
                      <TitledPage title='Keepr - Profit Share' component={<TokenDetails />} />
                    }
                  >
                    <Route path='' element={<ProfitShareHolders />} />
                    <Route path='distributions' element={<ProfitShareDistributions />} />
                    <Route path='payouts' element={<ProfitSharePayouts />} />
                  </Route>
                  <Route
                    path='payouts/create'
                    element={
                      <TitledPage
                        title='Keepr - Profit Share - Create Payout'
                        component={<CreatePayout />}
                      />
                    }
                  />
                  <Route
                    path='payouts/:payoutId'
                    element={
                      <TitledPage title='Keepr - Profit Share - Payout' component={<Payout />} />
                    }
                  />
                </Route>

                <Route path='assets/custom-asset/:tokenId'>
                  <Route
                    path=''
                    element={
                      <TitledPage title='Keepr - Custom Scheme' component={<TokenDetails />} />
                    }
                  >
                    <Route path='' element={<CustomTokenUsers />} />
                    <Route path='distributions' element={<CustomTokenDistributions />} />
                    <Route path='payouts' element={<CustomTokenPayouts />} />
                  </Route>

                  <Route
                    path='payouts/create'
                    element={
                      <TitledPage
                        title='Keepr - Custom Scheme - Create Payout'
                        component={<CreateSelectiveCashSharePayout />}
                      />
                    }
                  />
                  <Route
                    path='payouts/:payoutId'
                    element={
                      <TitledPage
                        title='Keepr - Custom Scheme - Payout'
                        component={<CustomTokenPayout />}
                      />
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path='settings'
              element={<TitledPage title='Keepr - Settings' component={<Settings />} />}
            >
              <Route
                element={
                  <PrivateRoute
                    isAllowed={!isRestrictedUse}
                    redirectPath={RESTRICTED_USE_ALLOWED_ROUTE}
                  />
                }
              >
                <Route index element={<General />} />
              </Route>
              <Route path='plans-and-billing' element={<PlansAndBilling />} />
            </Route>
          </Route>

          <Route path='*' element={<Error />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
