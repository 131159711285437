import { Stack, Typography, Paper, Link, CircularProgress, Tooltip } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useUserPayouts } from 'services/api/token'
import { ExercisedOptionsIcon, GenericTokenIcon } from 'icons'
import KeeprTable from 'components/KeeprTable'
import { useMemo, useState } from 'react'
import { UserPayout } from 'types/token/profitShare'
import { MRT_PaginationState, type MRT_ColumnDef } from 'material-react-table'
import { formatDate } from 'utils/dates'
import { NumericFormat } from 'react-number-format'
import { CURRENCY_SYMBOL } from 'types/common'
import Avatar from 'components/Avatar'
import { InfoOutlined as InfoOutlinedIcon, Event as EventIcon } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'

const PeopleDetailsPayouts = () => {
  const { userId = '' } = useParams<{ userId?: string }>()
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    //material-react-table and tanstack table under the hood use zero based index, our back-end uses 1 based index
    pageIndex: 0,
    pageSize: 5,
  })

  const { data: userPayouts, isLoading } = useUserPayouts({
    userId,
    pagination: {
      page: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
    },
    options: {
      placeholderData: (previousData) => previousData,
    },
  })

  const data = useMemo(() => userPayouts?.results, [userPayouts]) || []

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'token_name',
          header: 'Scheme',
          size: 220,
          Cell: ({ cell, row }) => {
            const tokenName = cell.getValue<string>()
            return (
              <Link
                component={RouterLink}
                to={`/assets/profit-share/${row.original.token_uuid}/payouts/${row.original.payout_id}`}
                sx={{ textDecoration: 'none' }}
              >
                <Stack direction='row' alignItems='center' gap={1}>
                  {row.original.token_thumbnail ? (
                    <Avatar
                      type='round'
                      src={row.original.token_thumbnail}
                      size={32}
                      name='Asset Logo'
                    />
                  ) : (
                    <GenericTokenIcon
                      sx={{ fontSize: 32, verticalAlign: 'middle', color: 'text.primary' }}
                    />
                  )}
                  <Stack alignItems='flex-start' overflow='hidden'>
                    <Typography variant='body2' color='text.primary' noWrap={true}>
                      {tokenName}
                    </Typography>
                    <Typography variant='caption' color='text.secondary'>
                      {row.original.token_abbreviation}
                    </Typography>
                  </Stack>
                </Stack>
              </Link>
            )
          },
        },
        {
          accessorKey: 'payout_title',
          header: 'Title',
          size: 220,
          Cell: ({ cell }) => {
            const title = cell.getValue<string>()
            return <Typography variant='body2'>{title}</Typography>
          },
        },
        {
          accessorKey: 'payout_dt',
          header: 'Payout date',
          size: 140,
          Cell: ({ cell }) => {
            const payoutDate = cell.getValue<string>()
            return (
              <Typography variant='body2'>{formatDate(payoutDate, 'MMM. dd, yyyy')}</Typography>
            )
          },
        },
        {
          id: 'payout_range',
          header: 'Date range',
          Header: (
            <Stack direction='row' alignItems='center' gap={0.5}>
              <EventIcon sx={{ fontSize: 16 }} />
              <Typography variant='subtitle2'>Date range</Typography>
            </Stack>
          ),
          size: 230,
          Cell: ({ row }) => {
            return (
              <Typography variant='body2'>
                {formatDate(row.original.start_dt, 'MMM. dd, yyyy')} -{' '}
                {formatDate(row.original.end_dt, 'MMM. dd, yyyy')}
              </Typography>
            )
          },
        },
        {
          accessorKey: 'payout_balance',
          header: 'Assets',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          size: 140,
          Cell: ({ cell }) => {
            const payoutBalance = cell.getValue<number>()
            return <NumericFormat value={payoutBalance} displayType='text' thousandSeparator />
          },
        },
        {
          accessorKey: 'token_value',
          header: 'Asset value',
          Header: (
            <Stack direction='row' alignItems='center' gap={0.5}>
              <Typography variant='subtitle2'>Asset value</Typography>
              <Tooltip title='This is an approximate value' placement='top-start'>
                <InfoOutlinedIcon
                  sx={{
                    fontSize: 16,
                    color: 'text.secondary',
                  }}
                />
              </Tooltip>
            </Stack>
          ),
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          size: 140,
          Cell: ({ cell, row }) => {
            const tokenValue = cell.getValue<number>()
            return (
              <Typography variant='body2'>
                <NumericFormat
                  value={tokenValue}
                  displayType={'text'}
                  decimalScale={2}
                  thousandSeparator
                  prefix={CURRENCY_SYMBOL[row.original.currency]}
                />
              </Typography>
            )
          },
        },
        {
          accessorKey: 'payout_value',
          header: 'Payout value',
          muiTableHeadCellProps: {
            align: 'right',
          },
          muiTableBodyCellProps: {
            align: 'right',
          },
          size: 140,
          Cell: ({ cell, row }) => {
            const payoutValue = cell.getValue<number>()
            return (
              <NumericFormat
                value={payoutValue}
                displayType='text'
                thousandSeparator
                prefix={CURRENCY_SYMBOL[row.original.currency]}
              />
            )
          },
        },
      ] as MRT_ColumnDef<UserPayout>[],
    [],
  )

  return (
    <Paper elevation={1}>
      {isLoading ? (
        <Stack height='270px' alignItems='center' justifyContent='center'>
          <CircularProgress size={30} disableShrink />
        </Stack>
      ) : userPayouts?.count ? (
        <KeeprTable
          columns={columns}
          data={data}
          onPaginationChange={setPagination}
          rowCount={userPayouts.count}
          enableSorting={false}
          state={{
            isLoading,
            pagination,
          }}
          muiTableBodyProps={{
            title: 'User payouts table',
          }}
        />
      ) : (
        <Stack alignItems='center' flexWrap='wrap' gap={2} px={4} py={6}>
          <Stack
            width={106}
            height={106}
            borderRadius='50%'
            sx={{ backgroundColor: 'grey.100' }}
            alignItems='center'
            justifyContent='center'
          >
            <ExercisedOptionsIcon sx={{ fontSize: '70px', color: 'grey.500' }} />
          </Stack>
          <Typography variant='h5'>No payouts yet</Typography>
          <Typography color='text.secondary'>
            When user is included in a payout event, it will be listed here.
          </Typography>
        </Stack>
      )}
    </Paper>
  )
}

export default PeopleDetailsPayouts
