import { useMemo, useState, useEffect } from 'react'
import { Button, CircularProgress, Paper, Stack } from '@mui/material'
import { parseTokenTypeTabsFormTokenBalance } from 'utils/parseTokenTypeTabsFormTokenBalance'
import { TOKEN_TYPE_OPTIONS, TokenTypeOptions } from 'types/token/basicToken'
import PeopleDetailsStockOptionsAssets from './PeopleDetailsStockOptionsAssets'
import PeopleDetailsProfitShareAssets from './PeopleDetailsProfitShareAssets'
import { useUserBalance } from 'services/api/user'
import { useParams } from 'react-router-dom'
import useErrorHandling from 'hooks/useErrorHandling'
import NoAssetsIllustration from 'assets/no-assets-illustration.svg'
import NoData from 'components/no-data/NoData'

const PeopleDetailsAssets = () => {
  const [activeTab, setActiveTab] = useState<TokenTypeOptions>()
  const handleError = useErrorHandling()
  const { userId = '' } = useParams<{ userId?: string }>()

  const {
    data: userBalance = [],
    isLoading,
    error: userDetailsError,
  } = useUserBalance({
    userId,
    options: {
      enabled: Boolean(userId),
      meta: {
        useCustomErrorHandling: true,
      },
    },
  })

  useEffect(() => {
    if (userDetailsError) {
      handleError(userDetailsError)
    }
  }, [handleError, userDetailsError])

  const tokenTypesOptions = useMemo(
    () => parseTokenTypeTabsFormTokenBalance(userBalance),
    [userBalance],
  )
  const selectedTab = activeTab || tokenTypesOptions[0]?.name
  const filteredUserBalances = userBalance.filter((ub) => ub.token.token_type === selectedTab)

  return (
    <Paper elevation={1}>
      <Stack data-testid='people-details-assets'>
        {isLoading ? (
          <Stack height='270px' alignItems='center' justifyContent='center'>
            <CircularProgress size={30} disableShrink />
          </Stack>
        ) : userBalance.length ? (
          <>
            <Stack direction='row' gap={1} p={2}>
              {tokenTypesOptions.map((dt) => (
                <Button
                  key={dt.name}
                  size='small'
                  variant={selectedTab === dt.name ? 'contained' : undefined}
                  onClick={() => setActiveTab(dt.name)}
                >
                  {dt.name}
                </Button>
              ))}
            </Stack>
            {selectedTab === TOKEN_TYPE_OPTIONS.StockOption ? (
              <PeopleDetailsStockOptionsAssets userBalance={filteredUserBalances} />
            ) : (
              <PeopleDetailsProfitShareAssets userBalance={filteredUserBalances} />
            )}
          </>
        ) : (
          <Stack flex={1} alignItems='center' justifyContent='center' gap={1} marginBottom='100px'>
            <NoData
              headline='You have no schemes yet'
              headlineSize='h4'
              content="Thats okay, they're still in reach. Schemes are a reward for your hard work and dedication.
          To discover how you can get them, reach out to your organization admin."
            >
              <img
                src={NoAssetsIllustration}
                alt='No assets'
                style={{ display: 'block', height: 200, width: 'auto' }}
              />
            </NoData>
          </Stack>
        )}
      </Stack>
    </Paper>
  )
}
export default PeopleDetailsAssets
